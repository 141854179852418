import { GET_INVOICE } from "../actions/types";
const initialState = {
    invoiceData: {
       docs: [],
       totalDocs: 0,
       limit: 0,
       page: 0,
       totalPages: 0,
       pagingCounter: 0,
       hasPrevPage: false,
       hasNextPage: false,
       prevPage: null,
       nextPage: null
    },
    invoiceLoading: true,
}

const invoiceReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_INVOICE:
            return { ...state, invoiceLoading: false, invoiceData: payload};
        default:
            return state;
    }

}
export default invoiceReducer;