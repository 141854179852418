import { GET_USER_GRAPH } from "../actions/types";
const initialState = {
  userGraph: {
    graph: [],
    month: [],
    data: [],
  },
};

const graphReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_GRAPH:
      return {
        ...state,
        userGraph: payload,
      };

    default:
      return state;
  }
};
export default graphReducer;
