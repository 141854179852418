export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALL_ALERT = "DELETE_ALL_ALERT";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_USERS = "GET_USERS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_ALL_ACTIVE_USERS = "GET_ALL_ACTIVE_USERS";
export const GET_GAMES = "GET_GAMES";
export const GET_INVOICE = "GET_INVOICE";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_CLIENT_DETAIL = "GET_CLIENT_DETAIL";
export const GET_TODOS = "GET_TODOS";
export const GET_NEW_TODOS = "GET_NEW_TODOS";
export const ADD_TODO = "ADD_TODO";
export const GET_TODO = "GET_TODO";
export const UPDATE_TODO = "UPDATE_TODO";
export const UPDATE_TODO_STATUS = "UPDATE_TODO_STATUS";
export const SORT_TODO = "SORT_TODO";
export const SORT_TODO_CUSTOM = "SORT_TODO_CUSTOM";
export const DELETE_TODO = "DELETE_TODO";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LABELS = "GET_LABELS";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_HOBBY = "GET_HOBBY";
export const UPDATE_HOBBY = "UPDATE_HOBBY";
export const DELETE_HOBBY = "DELETE_HOBBY";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";

export const GET_MAJOR = "GET_MAJOR";
export const UPDATE_MAJOR = "UPDATE_MAJOR";
export const DELETE_MAJOR = "DELETE_MAJOR";
export const GET_USER_GRAPH = "GET_USER_GRAPH";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT_DETAILS = "GET_EVENT_DETAILS";

// GET_SETTINGS, SETTINGS_FAIL
export const GET_SETTINGS = "GET_SETTINGS";
export const SETTINGS_FAIL = "SETTINGS_FAIL ";
//
export const GET_ALL_FAQ = "GET_ALL_FAQ";
export const FAQ_FAIL = "FAQ_FAIL";

// Category
export const GET_CATEGORY = "GET_CATEGORY";
export const CATEGORY_FAIL = "CATEGORY_FAIL";

// Sub Catecory
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const SUB_CATEGORY_FAIL = "SUB_CATEGORY_FAIL";

// POST
export const GET_POST = "GET_POST";

// MEDIA
export const GET_ALL_MEDIA = "GET_ALL_MEDIA";

// MEDIA
export const GET_ALL_CMS = "GET_ALL_CMS";
