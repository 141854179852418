import { combineReducers } from "redux";
import alertReducers from "./alertReducer";
import dashboardReducer from "./dashboardReducer";
import userReducer from "./userReducer";
import gameReducer from "./gameReducer";
import invoiceReducer from "./invoiceReducer";
import transactionHistoryReducer from "./transactionHistoryReducer"
import todoReducers from "./todoReducer.js";
import graphReducer from "./graphReducer.js";
import languageReducer from "./languageReducer.js";
import labelReducer from "./labelReducer.js";
import settingReducer from "./settingReducer";
import faqReducer from "./faqReducer.js";
import categoryReducer from "./categoryReducer";
import postReducers from "./postReducers";
import mediaManagement from "./mediaReducers.js";
import contentManagement from "./cmsReducer.js";
import authReducers from "./authReducers";

export default combineReducers({
  auth: authReducers,
  alert: alertReducers,
  
  dashboard: dashboardReducer,
  users: userReducer,
  games: gameReducer,
  invoice: invoiceReducer,
  transaction: transactionHistoryReducer,
  todos: todoReducers,
  categories: categoryReducer,
  graphs: graphReducer,
  cmsDetails: contentManagement,
  languages: languageReducer,
  mediaDetails: mediaManagement,
  labels: labelReducer,
  setting: settingReducer,
  faq: faqReducer,
  post: postReducers,
});
