import {
  GET_USERS,
  GET_ALL_ACTIVE_USERS,
  GET_USER_DETAILS,
} from "../actions/types";
const initialState = {
  userData: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
  user: {},
  userAllData: [],
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USERS:
      return { ...state, loading: false, userData: payload };
    case GET_ALL_ACTIVE_USERS:
      return { ...state, loading: false, userAllData: payload };
    case GET_USER_DETAILS:
      return { ...state, loading: false, user: payload };

    default:
      return state;
  }
};
export default userReducer;
