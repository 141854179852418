import { GET_DASHBOARD_DATA } from "../actions/types"
const initialState = {
    dashboardData:{},
    dashboardLoading: true,
}

const dashboardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DASHBOARD_DATA:
            return { ...state, dashboardLoading: false, dashboardData: payload};
        default:
            return state;
    }
};
export default dashboardReducer;