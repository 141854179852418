import { GET_POST } from "../actions/types";

const initialState = {
  posts: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
};

const postReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_POST:
      return {
        posts: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default postReducers;
